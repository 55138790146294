<template>
  <section>
    <el-card
      class="page_section_card"
      v-for="(item, index) in options"
      :key="index"
    >
      <div slot="header" class="clearfix">
        <span>{{ item.title }}</span>
      </div>
      <section>
        <el-form label-width="120px">
          <el-form-item label="开关：">
            <el-switch
              v-model="item.status"
              :active-value="1"
              :inactive-value="0"
            ></el-switch>
          </el-form-item>
          <el-form-item label="默认展示：">
            <el-button type="primary" @click="addTag(item)">重新设置</el-button>
            <div
              class="display_flex align_item_center flex_wrap_wrap"
              v-if="Array.isArray(item.content) && item.content.length > 0"
            >
              <el-tag
                closable
                @close="handleCloseTag(item, key, index)"
                style="margin: 10px 10px 0 0"
                v-for="(ele, key) in item.content"
                :key="ele.id"
                >{{ ele.name }}</el-tag
              >
            </div>
          </el-form-item>
        </el-form>
      </section>
    </el-card>
    <common-transfer
      :title="currentSetItem.title"
      :show="showTransfer"
      :allTags="allTags"
      :defaultTags="defaultTags"
      @close="closeTranser"
    ></common-transfer>
  </section>
</template>
<script>
import commonTransfer from "../../../../components/commonTransfer.vue";
export default {
  components: { commonTransfer },
  props: {
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showTransfer: false,
      allTags: [],
      defaultTags: [],
      allCountrys: [],
      allCategories: [],
      currentSetItem: {},
      allFollowers: [],
    };
  },
  created() {
    this.getCountrys();
    if (this.type == "facebook") {
      this.getCategories();
    } else if (this.type == "tiktok") {
      this.getTiktokCates();
    }
    this.getFollowers();
  },
  watch: {
    options: {
      handler(n) {
        this.$emit("getContentConditions", n);
      },
      deep: true,
    },
  },
  methods: {
    closeTranser(data) {
      this.showTransfer = data.show;
      if (data.ids?.length > 0) {
        let arr = [];
        data.ids.forEach((item) => {
          arr = arr.concat(
            this.allTags.filter((tag) => {
              return tag.id == item;
            })
          );
        });
        this.currentSetItem.content = arr;
        this.options[
          this.options.findIndex(
            (item) => item.keyword === this.currentSetItem.keyword
          )
        ] = this.currentSetItem;
      }
    },
    handleCloseTag(item, key, index) {
      this.options[index].content.splice(key, 1);
      console.log(this.options);
    },
    addTag(item) {
      console.log(item);
      this.currentSetItem = item;
      if (item.keyword === "country") {
        this.allTags = this.allCountrys.map((item) => {
          return {
            name: item.country_name,
            id: item.id,
          };
        });
        if (item.content?.length > 0) {
          this.defaultTags = item.content.map((item) => {
            return {
              name: item.country_name,
              id: item.id,
            };
          });
        } else {
          this.defaultTags = [];
        }
      } else if (item.keyword === "category") {
        this.allTags = this.allCategories.map((item) => {
          if (this.type === "facebook") {
            return {
              name: item.category,
              id: item.category,
            };
          } else {
            return {
              name: item.name,
              id: item.name,
            };
          }
        });
        if (item.content?.length > 0) {
          this.defaultTags = item.content.map((item) => {
            return {
              name: item.name,
              id: item.id,
            };
          });
        } else {
          this.defaultTags = [];
        }
      } else if (item.keyword === "followers") {
        this.allTags = this.allFollowers.map((item) => {
          return {
            name: item.name,
            id: item.id,
          };
        });
        if (item.content?.length > 0) {
          this.defaultTags = item.content.map((item) => {
            return {
              name: item.name,
              id: item.id,
            };
          });
        } else {
          this.defaultTags = [];
        }
      } else {
        this.allTags = [];
      }

      console.log(this.defaultTags);
      this.showTransfer = true;
    },
    getCountrys() {
      this.common.getCountrys().then((res) => {
        if (res.length > 0) {
          this.allCountrys = res;
        }
      });
    },
    getFollowers() {
      this.common.getFollowers().then((res) => {
        if (res.length > 0) {
          this.allFollowers = res;
        }
      });
    },
    getCategories() {
      this.axios("get", `${this.$baseUrl}v1/categoryFb/topLists`, {
        page: 1,
        page_num: 1000,
      }).then((res) => {
        if (res.code == 1) {
          this.allCategories = res.data.data;
        }
      });
    },
    getTiktokCates() {
      this.axios("get", `${this.$baseUrl}v1/TiktokUser/getClassifyList`, {
        page: 1,
        page_num: 1000,
      }).then((res) => {
        if (res.code == 1) {
          this.allCategories = res.data.data;
        }
      });
    },
  },
};
</script>