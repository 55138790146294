<template>
  <div>
    <el-dialog
      :destroy-on-close="true"
      width="625px"
      :title="`设置 ${title}`"
      :visible.sync="dialogTableVisible"
      :before-close="close"
    >
      <el-transfer
        v-model="value"
        filterable
        :titles="['全部列表', '已选列表']"
        :props="{
          key: 'id',
          label: 'name',
        }"
        :data="data"
      >
      </el-transfer>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="comfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      default: "",
      type: String
    },
    show: {
      default: false,
      type: Boolean,
    },
    defaultTags: {
      default: () => {
        return [];
      },
      type: Array,
    },
    allTags: {
      default: () => {
        return [];
      },
      type: Array,
    },
  },
  watch: {
    show(n) {
      this.dialogTableVisible = n;
    },
    allTags(n) {
      this.data = n;
    },
    defaultTags(n, o) {
      if (n != o) {
        this.value = n.map(i => {
          return i.id;
        })
      }
    }
  },
  data() {
    return {
      dialogTableVisible: false,
      value: [],
      data: [],
    };
  },
  methods: {
    close() {
      this.dialogTableVisible = false;
      this.$emit("close", {
        show: this.dialogTableVisible,
      });
    },
    comfirm() {
      if (this.value.length > 0) {
        if (this.value.length > 20) {
          this.$message({
            message: "最多可选20个",
            type: "warning",
          });
        } else {
          this.dialogTableVisible = false;
          this.$emit("close", {
            show: this.dialogTableVisible,
            ids: this.value
          });
        }
      }
    },
  },
};
</script>